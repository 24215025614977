export const API_URL = "https://pristera.ru/data"

export const COUNTRIES = [
  {
    "name": "Russian Federation",
    "value": "+7",
    "icon": "🇷🇺",
    "code": "ru"
  },
  {
    "name": "Afghanistan",
    "value": "+73",
    "icon": "🇦🇫",
    "code": "af"
  },
  {
    "name": "Albania",
    "value": "+94",
    "icon": "🇦🇱",
    "code": "al"
  },
  {
    "name": "Algeria",
    "value": "+95",
    "icon": "🇩🇿",
    "code": "dz"
  },
  {
    "name": "American Samoa",
    "value": "+96",
    "icon": "🇦🇸",
    "code": "as"
  },
  {
    "name": "Andorra",
    "value": "+97",
    "icon": "🇦🇩",
    "code": "ad"
  },
  {
    "name": "Angola",
    "value": "+98",
    "icon": "🇦🇴",
    "code": "ao"
  },
  {
    "name": "Anguilla",
    "value": "+1264",
    "icon": "🇦🇮",
    "code": "ai"
  },
  {
    "name": "Antigua and Barbuda",
    "value": "+1268",
    "icon": "🇦🇬",
    "code": "ag"
  },
  {
    "name": "Argentina",
    "value": "+54",
    "icon": "🇦🇷",
    "code": "ar"
  },
  {
    "name": "Armenia",
    "value": "+374",
    "icon": "🇦🇲",
    "code": "am"
  },
  {
    "name": "Aruba",
    "value": "+297",
    "icon": "🇦🇼",
    "code": "aw"

  },
  {
    "name": "Australia",
    "value": "+61",
    "icon": "🇦🇺",
    "code": "au"
  },
  {
    "name": "Austria",
    "value": "+43",
    "icon": "🇦🇹",
    "code": "at"
  },
  {
    "name": "Azerbaijan",
    "value": "+994",
    "icon": "🇦🇿",
    "code": "az"
  },
  {
    "name": "Bahamas",
    "value": "+1242",
    "icon": "🇧🇸",
    "code": "bs"
  },
  {
    "name": "Bahrain",
    "value": "+973",
    "icon": "🇧🇭",
    "code": "bh"
  },
  {
    "name": "Bangladesh",
    "value": "+880",
    "icon": "🇧🇩",
    "code": "bd"
  },
  {
    "name": "Barbados",
    "value": "+1246",
    "icon": "🇧🇧",
    "code": "bb"
  },
  {
    "name": "Belarus",
    "value": "+375",
    "icon": "🇧🇾",
    "code": "by"
  },
  {
    "name": "Belgium",
    "value": "+32",
    "icon": "🇧🇪",
    "code": "be"
  },
  {
    "name": "Belize",
    "value": "+501",
    "icon": "🇧🇿",
    "code": "bz"
  },
  {
    "name": "Benin",
    "value": "+25",
    "icon": "🇧🇯",
    "code": "bj"
  },
  {
    "name": "Bermuda",
    "value": "+229",
    "icon": "🇧🇲",
    "code": "bm"
  },
  {
    "name": "Bhutan",
    "value": "+975",
    "icon": "🇧🇹",
    "code": "bt"


  },
  {
    "name": "Bolivia",
    "value": "+591",
    "icon": "🇧🇴",
    "code": "bo"
  },
  {
    "name": "Bosnia and Herzegovina",
    "value": "+387",
    "icon": "🇧🇦",
    "code": "ba"
  },
//   {
//     "name": "Botswana",
//     "value": "BW"
//   },
//   {
//     "name": "Bouvet Island",
//     "value": "BV"
//   },
//   {
//     "name": "Brazil",
//     "value": "BR"
//   },
//   {
//     "name": "British Indian Ocean Territory",
//     "value": "IO"
//   },
//   {
//     "name": "Brunei Darussalam",
//     "value": "BN"
//   },
//   {
//     "name": "Bulgaria",
//     "value": "BG"
//   },
//   {
//     "name": "Burkina Faso",
//     "value": "BF"
//   },
//   {
//     "name": "Burundi",
//     "value": "BI"
//   },
  {
    "name": "Cambodia",
    "value": "+855",
    "icon": "🇰🇭",
    "code": "kh"
  },
  {
    "name": "Cameroon",
    "value": "+237",
    "icon": "🇨🇲",
    "code": "cm"
  },
//   {
//     "name": "Canada",
//     "value": "CA"
//   },
//   {
//     "name": "Cape Verde",
//     "value": "CV"
//   },
//   {
//     "name": "Cayman Islands",
//     "value": "KY"
//   },
//   {
//     "name": "Central African Republic",
//     "value": "CF"
//   },
//   {
//     "name": "Chad",
//     "value": "TD"
//   },
//   {
//     "name": "Chile",
//     "value": "CL"
//   },
//   {
//     "name": "China",
//     "value": "CN"
//   },
//   {
//     "name": "Christmas Island",
//     "value": "CX"
//   },
//   {
//     "name": "Cocos (Keeling) Islands",
//     "value": "CC"
//   },
//   {
//     "name": "Colombia",
//     "value": "CO"
//   },
//   {
//     "name": "Comoros",
//     "value": "KM"
//   },
//   {
//     "name": "Congo",
//     "value": "CG"
//   },
//   {
//     "name": "Congo, The Democratic Republic of the",
//     "value": "CD"
//   },
//   {
//     "name": "Cook Islands",
//     "value": "CK"
//   },
//   {
//     "name": "Costa Rica",
//     "value": "CR"
//   },
//   {
//     "name": "Cote D'Ivoire",
//     "value": "CI"
//   },
//   {
//     "name": "Croatia",
//     "value": "HR"
//   },
//   {
//     "name": "Cuba",
//     "value": "CU"
//   },
//   {
//     "name": "Cyprus",
//     "value": "CY"
//   },
//   {
//     "name": "Czech Republic",
//     "value": "CZ"
//   },
  {
    "name": "Denmark",
    "value": "+45",
    "icon": "🇩🇰",
    "code": "dk"
  },
  {
    "name": "Djibouti",
    "value": "+253",
    "icon": "🇩🇯",
    "code": "dj"
  },
//   {
//     "name": "Dominica",
//     "value": "DM"
//   },
//   {
//     "name": "Dominican Republic",
//     "value": "DO"
//   },
  {
    "name": "Ecuador",
    "value": "+593",
    "icon": "🇪🇨",
    "code": "ec"
  },
  {
    "name": "Egypt",
    "value": "+20",
    "icon": "🇪🇬",
    "code": "eg"
  },
//   {
//     "name": "El Salvador",
//     "value": "SV"
//   },
//   {
//     "name": "Equatorial Guinea",
//     "value": "GQ"
//   },
//   {
//     "name": "Eritrea",
//     "value": "ER"
//   },
//   {
//     "name": "Estonia",
//     "value": "EE"
//   },
//   {
//     "name": "Ethiopia",
//     "value": "ET"
//   },
  {
    "name": "Falkland Islands (Malvinas)",
    "value": "+500",
    "icon": "🇫🇰",
    "code": "fk"
  },
  {
    "name": "Faroe Islands",
    "value": "+298",
    "icon": "🇫🇴",
    "code": "fo"
  },
//   {
//     "name": "Fiji",
//     "value": "FJ"
//   },
//   {
//     "name": "Finland",
//     "value": "FI"
//   },
//   {
//     "name": "France",
//     "value": "FR"
//   },
//   {
//     "name": "French Guiana",
//     "value": "GF"
//   },
//   {
//     "name": "French Polynesia",
//     "value": "PF"
//   },
//   {
//     "name": "French Southern Territories",
//     "value": "TF"
//   },
  {
    "name": "Gabon",
    "value": "+241",
    "icon": "🇬🇦",
    "code": "ga"
  },
  {
    "name": "Gambia",
    "value": "+220",
    "icon": "🇬🇲",
    "code": "gm"

  },
//   {
//     "name": "Georgia",
//     "value": "GE"
//   },
//   {
//     "name": "Germany",
//     "value": "DE"
//   },
//   {
//     "name": "Ghana",
//     "value": "GH"
//   },
//   {
//     "name": "Gibraltar",
//     "value": "GI"
//   },
//   {
//     "name": "Greece",
//     "value": "GR"
//   },
//   {
//     "name": "Greenland",
//     "value": "GL"
//   },
//   {
//     "name": "Grenada",
//     "value": "GD"
//   },
//   {
//     "name": "Guadeloupe",
//     "value": "GP"
//   },
//   {
//     "name": "Guam",
//     "value": "GU"
//   },
//   {
//     "name": "Guatemala",
//     "value": "GT"
//   },
//   {
//     "name": "Guernsey",
//     "value": "GG"
//   },
//   {
//     "name": "Guinea",
//     "value": "GN"
//   },
//   {
//     "name": "Guinea-Bissau",
//     "value": "GW"
//   },
//   {
//     "name": "Guyana",
//     "value": "GY"
//   },
  {
    "name": "Haiti",
    "value": "+509",
    "icon": "🇭🇹",
    "code": "ht"
  },
//   {
//     "name": "Heard Island and Mcdonald Islands",
//     "value": "+509",
//     "icon": "🇭🇹",
//     "code": "hm"
//   },
//   {
//     "name": "Holy See (Vatican City State)",
//     "value": "VA"
//   },
  {
    "name": "Honduras",
    "value": "+504",
    "icon": "🇭🇳",
    "code": "hn"
  },
//   {
//     "name": "Hong Kong",
//     "value": "HK"
//   },
//   {
//     "name": "Hungary",
//     "value": "HU"
//   },
  {
    "name": "Iceland",
    "value": "+354",
    "icon": "🇮🇸",
    "code": "is"
  },
  {
    "name": "India",
    "value": "+91",
    "icon": "🇮🇸",
    "code": "🇮🇳"

  },
//   {
//     "name": "Indonesia",
//     "value": "ID"
//   },
//   {
//     "name": "Iran, Islamic Republic Of",
//     "value": "IR"
//   },
//   {
//     "name": "Iraq",
//     "value": "IQ"
//   },
//   {
//     "name": "Ireland",
//     "value": "IE"
//   },
//   {
//     "name": "Isle of Man",
//     "value": "IM"
//   },
//   {
//     "name": "Israel",
//     "value": "IL"
//   },
//   {
//     "name": "Italy",
//     "value": "IT"
//   },
  {
    "name": "Jamaica",
    "value": "+1876",
    "icon": "🇯🇲",
    "code": "jm"
  },
  {
    "name": "Japan",
    "value": "+81",
    "icon": "🇯🇵",
    "code": "jp"

  },

//   {
//     "name": "Jersey",
//     "value": "JE"
//   },
//   {
//     "name": "Jordan",
//     "value": "JO"
//   },
//   {
//     "name": "Kazakhstan",
//     "value": "+7",
//     "icon": "🇰🇿",
//     "code": "kz"
//   },
  {
    "name": "Kenya",
    "value": "+254",
    "icon": "🇰🇪",
    "code": "ke"
  },
//   {
//     "name": "Kiribati",
//     "value": "KI"
//   },
//   {
//     "name": "Korea, Democratic People'S Republic of",
//     "value": "KP"
//   },
//   {
//     "name": "Korea, Republic of",
//     "value": "KR"
//   },
//   {
//     "name": "Kuwait",
//     "value": "KW"
//   },
//   {
//     "name": "Kyrgyzstan",
//     "value": "KG"
//   },
//   {
//     "name": "Lao People'S Democratic Republic",
//     "value": "LA"
//   },
  {
    "name": "Latvia",
    "value": "+371",
    "icon": "🇰🇪",
    "code": "🇱🇻"
  },
  {
    "name": "Lebanon",
    "value": "+961",
    "icon": "🇱🇧",
    "code": "lb"
  },
//   {
//     "name": "Lesotho",
//     "value": "LS"
//   },
//   {
//     "name": "Liberia",
//     "value": "LR"
//   },
//   {
//     "name": "Libyan Arab Jamahiriya",
//     "value": "LY"
//   },
//   {
//     "name": "Liechtenstein",
//     "value": "LI"
//   },
//   {
//     "name": "Lithuania",
//     "value": "LT"
//   },
//   {
//     "name": "Luxembourg",
//     "value": "LU"
//   },
  {
    "name": "Macao",
    "value": "+853",
    "icon": "🇲🇴",
    "code": "mo"
  },
//   {
//     "name": "Madagascar",
//     "value": "MG"
//   },
//   {
//     "name": "Malawi",
//     "value": "MW"
//   },
//   {
//     "name": "Malaysia",
//     "value": "MY"
//   },
//   {
//     "name": "Maldives",
//     "value": "MV"
//   },
//   {
//     "name": "Mali",
//     "value": "ML"
//   },
//   {
//     "name": "Malta",
//     "value": "MT"
//   },
//   {
//     "name": "Marshall Islands",
//     "value": "MH"
//   },
//   {
//     "name": "Martinique",
//     "value": "MQ"
//   },
//   {
//     "name": "Mauritania",
//     "value": "MR"
//   },
//   {
//     "name": "Mauritius",
//     "value": "MU"
//   },
//   {
//     "name": "Mayotte",
//     "value": "YT"
//   },
//   {
//     "name": "Mexico",
//     "value": "MX"
//   },
//   {
//     "name": "Micronesia, Federated States of",
//     "value": "FM"
//   },
//   {
//     "name": "Moldova, Republic of",
//     "value": "MD"
//   },
//   {
//     "name": "Monaco",
//     "value": "MC"
//   },
//   {
//     "name": "Mongolia",
//     "value": "MN"
//   },
//   {
//     "name": "Montserrat",
//     "value": "MS"
//   },
//   {
//     "name": "Morocco",
//     "value": "MA"
//   },
//   {
//     "name": "Mozambique",
//     "value": "MZ"
//   },
//   {
//     "name": "Myanmar",
//     "value": "MM"
//   },
  {
    "name": "Namibia",
    "value": "+264",
    "icon": "🇳🇦",
    "code": "na"
  },
  {
    "name": "Nauru",
    "value": "+674",
    "icon": "🇳🇷",
    "code": "nr"
  },
//   {
//     "name": "Nepal",
//     "value": "NP"
//   },
//   {
//     "name": "Netherlands",
//     "value": "NL"
//   },
//   {
//     "name": "Netherlands Antilles",
//     "value": "AN"
//   },
//   {
//     "name": "New Caledonia",
//     "value": "NC"
//   },
//   {
//     "name": "New Zealand",
//     "value": "NZ"
//   },
//   {
//     "name": "Nicaragua",
//     "value": "NI"
//   },
//   {
//     "name": "Niger",
//     "value": "NE"
//   },
//   {
//     "name": "Nigeria",
//     "value": "NG"
//   },
//   {
//     "name": "Niue",
//     "value": "NU"
//   },
//   {
//     "name": "Norfolk Island",
//     "value": "NF"
//   },
//   {
//     "name": "Northern Mariana Islands",
//     "value": "MP"
//   },
//   {
//     "name": "Norway",
//     "value": "NO"
//   },
  {
    "name": "Oman",
    "value": "+968",
    "icon": "🇴🇲",
    "code": "om"
  },
  {
    "name": "Pakistan",
    "value": "+92",
    "icon": "🇵🇰",
    "code": "pk"
  },
  {
    "name": "Palau",
    "value": "+680",
    "icon": "🇵🇼",
    "code": "pw"
  },
//   {
//     "name": "Palestinian Territory, Occupied",
//     "value": "PS"
//   },
//   {
//     "name": "Panama",
//     "value": "PA"
//   },
//   {
//     "name": "Papua New Guinea",
//     "value": "PG"
//   },
//   {
//     "name": "Paraguay",
//     "value": "PY"
//   },
//   {
//     "name": "Peru",
//     "value": "PE"
//   },
//   {
//     "name": "Philippines",
//     "value": "PH"
//   },
//   {
//     "name": "Pitcairn",
//     "value": "PN"
//   },
//   {
//     "name": "Poland",
//     "value": "PL"
//   },
//   {
//     "name": "Portugal",
//     "value": "PT"
//   },
//   {
//     "name": "Puerto Rico",
//     "value": "PR"
//   },
  {
    "name": "Qatar",
    "value": "+974",
    "icon": "🇶🇦",
    "code": "qa"
  },
//   {
//     "name": "Reunion",
//     "value": "RE"
//   },
  {
    "name": "Romania",
    "value": "+40",
    "icon": "🇷🇴",
    "code": "ro"
  },
  {
    "name": "Rwanda",
    "value": "+250",
    "icon": "🇷🇼",
    "code": "rw"
  },
//   {
//     "name": "Saint Helena",
//     "value": "+250",
//     "icon": "🇷🇼",
//     "code": "sh"
//   },
//   {
//     "name": "Saint Kitts and Nevis",
//     "value": "+250",
//     "icon": "🇷🇼",
//     "code": "kn"
//   },
//   {
//     "name": "Saint Lucia",
//     "value": "LC"
//   },
//   {
//     "name": "Saint Pierre and Miquelon",
//     "value": "PM"
//   },
//   {
//     "name": "Saint Vincent and the Grenadines",
//     "value": "VC"
//   },
  {
    "name": "Samoa",
    "value": "+685",
    "icon": "🇼🇸",
    "code": "ws"
  },
  {
    "name": "San Marino",
    "value": "+378",
    "icon": "🇸🇲",
    "code": "sm"
  },
//   {
//     "name": "Sao Tome and Principe",
//     "value": "ST"
//   },
//   {
//     "name": "Saudi Arabia",
//     "value": "SA"
//   },
//   {
//     "name": "Senegal",
//     "value": "SN"
//   },
//   {
//     "name": "Serbia and Montenegro",
//     "value": "CS"
//   },
//   {
//     "name": "Seychelles",
//     "value": "SC"
//   },
//   {
//     "name": "Sierra Leone",
//     "value": "SL"
//   },
//   {
//     "name": "Singapore",
//     "value": "SG"
//   },
//   {
//     "name": "Slovakia",
//     "value": "SK"
//   },
//   {
//     "name": "Slovenia",
//     "value": "SI"
//   },
//   {
//     "name": "Solomon Islands",
//     "value": "SB"
//   },
//   {
//     "name": "Somalia",
//     "value": "SO"
//   },
//   {
//     "name": "South Africa",
//     "value": "ZA"
//   },
//   {
//     "name": "South Georgia and the South Sandwich Islands",
//     "value": "GS"
//   },
//   {
//     "name": "Spain",
//     "value": "ES"
//   },
//   {
//     "name": "Sri Lanka",
//     "value": "LK"
//   },
//   {
//     "name": "Sudan",
//     "value": "SD"
//   },
//   {
//     "name": "Suriname",
//     "value": "SR"
//   },
//   {
//     "name": "Svalbard and Jan Mayen",
//     "value": "SJ"
//   },
//   {
//     "name": "Swaziland",
//     "value": "SZ"
//   },
//   {
//     "name": "Sweden",
//     "value": "SE"
//   },
//   {
//     "name": "Switzerland",
//     "value": "CH"
//   },
//   {
//     "name": "Syrian Arab Republic",
//     "value": "SY"
//   },
  {
    "name": "Taiwan, Province of China",
    "value": "+886",
    "icon": "🇹🇼",
    "code": "tw"

  },
  {
    "name": "Tajikistan",
    "value": "+992",
    "icon": "🇹🇯",
    "code": "tj"
  },
//   {
//     "name": "Tanzania, United Republic of",
//     "value": "TZ"
//   },
//   {
//     "name": "Thailand",
//     "value": "TH"
//   },
//   {
//     "name": "Timor-Leste",
//     "value": "TL"
//   },
//   {
//     "name": "Togo",
//     "value": "TG"
//   },
//   {
//     "name": "Tokelau",
//     "value": "TK"
//   },
//   {
//     "name": "Tonga",
//     "value": "TO"
//   },
//   {
//     "name": "Trinidad and Tobago",
//     "value": "TT"
//   },
//   {
//     "name": "Tunisia",
//     "value": "TN"
//   },
//   {
//     "name": "Turkey",
//     "value": "TR"
//   },
//   {
//     "name": "Turkmenistan",
//     "value": "TM"
//   },
//   {
//     "name": "Turks and Caicos Islands",
//     "value": "TC"
//   },
//   {
//     "name": "Tuvalu",
//     "value": "TV"
//   },
  {
    "name": "Uganda",
    "value": "+256",
    "icon": "🇺🇬",
    "code": "ug"
  },
  {
    "name": "Ukraine",
    "value": "+380",
    "icon": "🇺🇦",
    "code": "ua"
  },
//   {
//     "name": "United Arab Emirates",
//     "value": "AE"
//   },
//   {
//     "name": "United Kingdom",
//     "value": "GB"
//   },
//   {
//     "name": "United States",
//     "value": "US"
//   },
//   {
//     "name": "United States Minor Outlying Islands",
//     "value": "UM"
//   },
//   {
//     "name": "Uruguay",
//     "value": "UY"
//   },
//   {
//     "name": "Uzbekistan",
//     "value": "UZ"
//   },
  {
    "name": "Vanuatu",
    "value": "+678",
    "icon": "🇻🇺",
    "code": "vu"
  },
  {
    "name": "Venezuela",
    "value": "+58",
    "icon": "🇻🇪",
    "code": "ve"
  },
//   {
//     "name": "Viet Nam",
//     "value": "VN"
//   },
//   {
//     "name": "Virgin Islands, British",
//     "value": "VG"
//   },
//   {
//     "name": "Virgin Islands, U.S.",
//     "value": "VI"
//   },
//   {
//     "name": "Wallis and Futuna",
//     "value": "WF"
//   },
  {
    "name": "Yemen",
    "value": "+967",
    "icon": "🇾🇪",
    "code": "ye"
  },
  {
    "name": "Zambia",
    "value": "+260",
    "icon": "🇿🇲",
    "code": "zm"

  },
  {
    "name": "Zimbabwe",
    "value": "+263",
    "icon": "🇿🇼",
    "code": "zw"
  }
]
